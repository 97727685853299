@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  background-color: #f3f5fa;
}

.pointer {
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.noWrap {
  white-space: nowrap;
}

.truncate-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preLineBreak {
  white-space: pre-line;
}

.ck.ck-content {
  min-height: 250px;
  max-height: 350px;
}
