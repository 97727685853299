$navbar: 82px;
$sidebarWidth: 250px;
$smallSidebarWidth: 60px;

.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 10px rgba(187, 187, 187, 0.5);
  position: fixed;
  top: 0;
  left: calc($sidebarWidth + 1px);
  right: 0;

  .navLogo {
    filter: invert(1);
  }

  @media screen and (max-width: 767px) {
    left: calc($smallSidebarWidth + 1px);
  }
}

.sidebarContainer {
  color: white;
  width: $sidebarWidth;
  max-width: $sidebarWidth;
  border-right: 1px solid #eef0f8;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 6px 10px rgba(187, 187, 187, 0.5);

  @media screen and (max-width: 767px) {
    width: $smallSidebarWidth;
    max-width: $smallSidebarWidth;

    & .listItemIcon {
      min-width: auto;
    }
  }
}

.logo{
  padding: 20px 10px;
  margin-bottom: 30px;
  max-width: 180px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 767px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  img{
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
}

.mainContainer {
  height: calc(100vh - $navbar);
  margin-left: $sidebarWidth;
  margin-top: calc($navbar + 2px);
  overflow: auto;
  width: calc(100% - $sidebarWidth);
  padding: 20px;

  @media screen and (max-width: 767px) {
    margin-left: $smallSidebarWidth;
    width: calc(100% - $smallSidebarWidth);
    padding: 16px;
  }
}
